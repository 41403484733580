import { j as e } from "./jsx-runtime-B6kdoens.js";
import { useState as c } from "react";
import { A as a } from "./AlertDialog.component-DxOyUKT5.js";
import { B as j } from "./Badge-C0bj4DFU.js";
import { B as d } from "./Button-BrPm3sL-.js";
import { S as o } from "./Separator.component-BNi038DN.js";
import { S as f } from "./Stack-DHVjAP0n.js";
import { T as t } from "./Text-Bn-rHqca.js";
import { F as p } from "./FolderIcon.component-BgWaIq6n.js";
import { D as n } from "./DropdownMenu-CjqQt0vp.js";
import { I as l } from "./Icon-C5a5ARPP.js";
import { c as h } from "./utils-CJ9afRe1.js";
const u = (s) => /* @__PURE__ */ e.jsxs(n, { children: [
  /* @__PURE__ */ e.jsx(n.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsxs(
    d,
    {
      variant: "ghost",
      size: "sm",
      className: "gap gap-1 bg-white",
      children: [
        /* @__PURE__ */ e.jsx(l, { strokeWidth: 2, name: "FilterList", width: 16, height: 16 }),
        s.active === "MY_LOANS" ? "My Loans" : "All Loans",
        /* @__PURE__ */ e.jsx(l, { strokeWidth: 1.5, name: "NavArrowDown", width: 16, height: 16 })
      ]
    }
  ) }),
  /* @__PURE__ */ e.jsxs(
    n.Content,
    {
      align: "end",
      side: "bottom",
      alignOffset: 0,
      className: "p-2 w-36",
      children: [
        /* @__PURE__ */ e.jsxs(
          n.Item,
          {
            onSelect: () => s.onFilterChange("MY_LOANS"),
            className: "flex gap-2",
            children: [
              /* @__PURE__ */ e.jsx(l, { strokeWidth: 2, name: "UserBag", width: 16, height: 16 }),
              " ",
              /* @__PURE__ */ e.jsx(t, { size: "sm", children: "My Loans" })
            ]
          }
        ),
        /* @__PURE__ */ e.jsxs(
          n.Item,
          {
            onSelect: () => s.onFilterChange("ALL_LOANS"),
            className: "flex gap-2",
            children: [
              /* @__PURE__ */ e.jsx(l, { strokeWidth: 2, name: "Handbag", width: 16, height: 16 }),
              " ",
              /* @__PURE__ */ e.jsx(t, { size: "sm", children: "All Loans" })
            ]
          }
        )
      ]
    }
  )
] }), O = (s) => {
  const [m, x] = c("MY_LOANS"), [r, g] = c(null);
  return /* @__PURE__ */ e.jsxs(
    a,
    {
      open: s.open,
      onOpenChange: s.onOpenChange,
      children: [
        /* @__PURE__ */ e.jsx(a.Overlay, {}),
        /* @__PURE__ */ e.jsxs(a.Content, { className: "sm:max-w-lg w-lg", "aria-label": "Move to loan", children: [
          /* @__PURE__ */ e.jsxs(t, { className: "px-3 pt-3", size: "sm", children: [
            "Move ",
            s.items.length,
            " items to loan"
          ] }),
          /* @__PURE__ */ e.jsx(o, {}),
          /* @__PURE__ */ e.jsx(a.Header, { className: "px-4", children: /* @__PURE__ */ e.jsxs(t, { size: "sm", as: "div", children: [
            "Current Location : ",
            /* @__PURE__ */ e.jsx(j, { variant: "secondary", children: "My Shoebox" })
          ] }) }),
          /* @__PURE__ */ e.jsx(o, {}),
          !s.hideLoanFilter && /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
            /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(u, { active: m, onFilterChange: x }) }),
            /* @__PURE__ */ e.jsx(o, {})
          ] }),
          /* @__PURE__ */ e.jsx(
            "div",
            {
              className: "px-4 flex-1 flex gap-6 sm:gap-3 flex-col sm:max-h-[200px] overflow-y-auto scrollbar-stable",
              children: /* @__PURE__ */ e.jsx(
                "div",
                {
                  className: h("flex-1 flex flex-col gap-3"),
                  children: s.loans.filter((i) => m === "MY_LOANS" ? i.iAmAdded : m === "ALL_LOANS").map((i) => /* @__PURE__ */ e.jsxs(
                    f,
                    {
                      onClick: () => g((r == null ? void 0 : r.id) === i.id ? null : i),
                      row: !0,
                      space: "sm",
                      className: h(
                        "px-3 py-2 group flex min-h-10 items-center hover:bg-gray-neutral-30 rounded-lg transition-colors cursor-pointer",
                        { "bg-gray-neutral-30": (r == null ? void 0 : r.id) === i.id }
                      ),
                      children: [
                        /* @__PURE__ */ e.jsx(p, { className: "shrink-0" }),
                        /* @__PURE__ */ e.jsx(t, { truncate: !0, size: "sm", className: "flex-1", children: i.projectName }),
                        /* @__PURE__ */ e.jsx(a.Cancel, { asChild: !0, children: /* @__PURE__ */ e.jsx(
                          d,
                          {
                            onClick: () => {
                              s.onMoveConfirm(
                                i,
                                s.items
                              );
                            },
                            variant: "secondary",
                            size: "sm",
                            className: "hidden sm:inline-flex hover:text-blue-100 opacity-0 group-hover:opacity-100 w-0 group-hover:w-auto group-hover:px-2 overflow-hidden p-0",
                            children: "Move here"
                          }
                        ) })
                      ]
                    },
                    i.id
                  ))
                }
              )
            }
          ),
          /* @__PURE__ */ e.jsx(o, {}),
          /* @__PURE__ */ e.jsxs(a.Footer, { className: "shrink-0", children: [
            /* @__PURE__ */ e.jsx(a.Cancel, { children: "Cancel" }),
            /* @__PURE__ */ e.jsx(
              a.Action,
              {
                onClick: () => r && s.onMoveConfirm(
                  r,
                  s.items
                ),
                disabled: !r,
                children: "Move"
              }
            )
          ] })
        ] })
      ]
    }
  );
};
export {
  u as L,
  O as P
};
