import { j as t } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as p } from "react";
import { I as a } from "./index-CjaISrnp.js";
import "./Accordion-DtZRzadW.js";
import "./Avatar-95vFNu6x.js";
import "./Badge-C0bj4DFU.js";
import "./Button-BrPm3sL-.js";
import "./DropdownMenu-CjqQt0vp.js";
import "./Icon-C5a5ARPP.js";
import { I as i } from "./Input-vl7Xx2ol.js";
import "./Label-Bzu7sDuW.js";
import "./Text-Bn-rHqca.js";
import "./Tooltip-Dy7rqQaX.js";
import "./Stack-DHVjAP0n.js";
import "./Switch-fw2mabqr.js";
import "./Toast-Dk02TeEO.js";
import "./Toggle-Cn0qt44_.js";
const e = p(
  (m, o) => /* @__PURE__ */ t.jsx(a, { ...m, inputRef: o, mask: "999-999-9999", maskChar: "_", children: (r) => /* @__PURE__ */ t.jsx(
    i,
    {
      wrapClassName: r.wrapClassName,
      ...r,
      disabled: m.disabled,
      type: "tel",
      placeholder: "000-000-0000"
    }
  ) })
);
e.displayName = "PhoneNumberInput";
export {
  e as default
};
