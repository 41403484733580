import { j as t } from "./jsx-runtime-B6kdoens.js";
import { S as l } from "./Stack-DHVjAP0n.js";
import { u as r } from "./useMediaQuery-BLIs2rKV.js";
import { c as m } from "./utils-CJ9afRe1.js";
import { B as s } from "./Button-BrPm3sL-.js";
import { I as d } from "./Icon-C5a5ARPP.js";
import { T as i } from "./Tooltip-Dy7rqQaX.js";
import { createPortal as c } from "react-dom";
const x = (e) => /* @__PURE__ */ t.jsx(i.Provider, { children: /* @__PURE__ */ t.jsxs(
  l,
  {
    space: "md",
    row: !0,
    children: [
      /* @__PURE__ */ t.jsxs(i, { children: [
        /* @__PURE__ */ t.jsx(i.Trigger, { asChild: !0, children: /* @__PURE__ */ t.jsx(
          s,
          {
            onClick: () => {
              var a, n;
              return e.state === "MINIMIZED" ? (a = e.onMaximize) == null ? void 0 : a.call(e) : (n = e.onMinimize) == null ? void 0 : n.call(e);
            },
            spaceX: "custom",
            className: m("w-5 p-0 rounded-xs  h-5  aspect-square  text-black-dark", {
              " text-black-dark ": e.state === "MINIMIZED"
            }),
            size: "sm",
            variant: "custom",
            children: /* @__PURE__ */ t.jsx(d, { name: e.state === "MINIMIZED" ? "MinusSquareSolid" : "MinusSquare", width: 20, height: 20, strokeWidth: 1.5 })
          }
        ) }),
        /* @__PURE__ */ t.jsx(i.Content, { children: e.state === "MINIMIZED" ? "Maximize" : "Minimize" })
      ] }),
      /* @__PURE__ */ t.jsxs(i, { children: [
        /* @__PURE__ */ t.jsx(i.Trigger, { asChild: !0, children: /* @__PURE__ */ t.jsx(
          s,
          {
            onClick: () => {
              var a, n;
              return e.state === "MAXIMIZED" ? (a = e.onMaximize) == null ? void 0 : a.call(e) : (n = e.onFullScreen) == null ? void 0 : n.call(e);
            },
            spaceX: "custom",
            className: "w-5 p-0 rounded-xs h-5 aspect-square hidden lg:inline-flex ",
            size: "sm",
            variant: "custom",
            children: /* @__PURE__ */ t.jsx(d, { name: e.state === "MAXIMIZED" ? "Collapse" : "Expand", width: 20, height: 20, strokeWidth: 1.5 })
          }
        ) }),
        /* @__PURE__ */ t.jsx(i.Content, { children: e.state === "MAXIMIZED" ? "Collapse" : "Expand" })
      ] }),
      /* @__PURE__ */ t.jsxs(i, { children: [
        /* @__PURE__ */ t.jsx(i.Trigger, { asChild: !0, children: /* @__PURE__ */ t.jsx(
          s,
          {
            onClick: e.onClose,
            spaceX: "custom",
            className: "w-5 p-0 rounded-xs h-5 aspect-square",
            size: "sm",
            variant: "custom",
            children: /* @__PURE__ */ t.jsx(d, { name: "Cancel", width: 20, height: 20, strokeWidth: 1.5 })
          }
        ) }),
        /* @__PURE__ */ t.jsx(i.Content, { children: "Close" })
      ] })
    ]
  }
) }), h = (e) => {
  const a = r("(min-width: 1024px)"), n = () => {
    e.state === "MINIMIZED" ? e.onMaximize() : (e.state === "OPEN" || e.state === "MAXIMIZED") && e.onMinimize();
  };
  return /* @__PURE__ */ t.jsxs(t.Fragment, { children: [
    (e.state === "MAXIMIZED" || e.state === "OPEN" && !a) && /* @__PURE__ */ t.jsx("div", { className: "bg-black-primary/80 backdrop-blur-sm fixed z-dialog inset-0 w-full h-full" }),
    /* @__PURE__ */ t.jsxs(
      l,
      {
        className: m("z-dialog max-w-4xl w-full fixed mr-3 mb-3  shadow-sm border border-gray-neutral-80 rounded-md", {
          "max-w-xs w-full bg-blue-50 p-3 rounded-md h-auto bottom-0 right-0 mr-3 mb-3": e.state === "MINIMIZED",
          "bg-white h-full sm:h-[670px] sm:max-w-[95%] lg:max-w-4xl -translate-x-1/2 -translate-y-1/2 max-h-full top-1/2 left-1/2": e.state === "MAXIMIZED" || !a && e.state === "OPEN",
          "h-[516px] max-h-full max-w-3xl w-full fixed bottom-0 right-0 bg-white shadow-sm border border-gray-neutral-80": a && e.state === "OPEN"
        }),
        children: [
          /* @__PURE__ */ t.jsxs(l, { className: m("", {
            "p-2 bg-black-10 border-b border-gray-neutral-80": e.state === "MAXIMIZED" || e.state === "OPEN"
          }), row: !0, children: [
            /* @__PURE__ */ t.jsx(
              "div",
              {
                onClick: n,
                className: "flex flex-1 items-center overflow-hidden cursor-pointer",
                children: e.title
              }
            ),
            /* @__PURE__ */ t.jsx(
              x,
              {
                state: e.state,
                onClose: e.onClose,
                onMinimize: e.onMinimize,
                onFullScreen: e.onFullScreen,
                onMaximize: e.onMaximize
              }
            )
          ] }),
          /* @__PURE__ */ t.jsx(
            l,
            {
              "data-hidden": e.state === "MINIMIZED",
              className: "flex-1 overflow-hidden data-[hidden=true]:hidden",
              children: e.children
            }
          )
        ]
      }
    )
  ] });
}, o = (e) => c(e.children, document.body), E = (e) => e.state === "CLOSED" ? null : /* @__PURE__ */ t.jsx(o, { children: /* @__PURE__ */ t.jsx(h, { ...e }) });
export {
  E as default
};
