import { j as ce } from "./jsx-runtime-B6kdoens.js";
import { a as N, _ as E, $ as Ze } from "./index-28E3tTLh.js";
import * as Ge from "react";
import { forwardRef as O, useContext as qe, useState as D, useEffect as C, createElement as p, useRef as x, createContext as Je, useCallback as Y } from "react";
import { b as T, a as H, $ as Qe } from "./index-CTDw4Pi4.js";
import { $ as pe, a as ae } from "./index-Cp-Nu9_j.js";
import { $ as L, a as et } from "./index-CZBInFmv.js";
import { $ as tt } from "./index-En-smfNl.js";
import { R as ot, $ as nt } from "./Combination-DIVVjZIY.js";
import { $ as rt } from "./index-rKUeoTl7.js";
import { $ as mo, u as ct, a as at, o as st, s as it, f as dt, b as lt, c as ut, h as ft, l as pt } from "./index-DoIowKQ-.js";
import { $ as $t } from "./index-DaaF0TLE.js";
import vt from "react-dom";
import { $ as $e } from "./index-Cs5eRmmP.js";
import { h as bt } from "./component-BNzimWXw.js";
import { c as mt } from "./utils-CJ9afRe1.js";
const Q = "dismissableLayer.update", ht = "dismissableLayer.pointerDownOutside", gt = "dismissableLayer.focusOutside";
let se;
const Pt = /* @__PURE__ */ Je({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), wt = /* @__PURE__ */ O((e, o) => {
  var t;
  const { disableOutsidePointerEvents: n = !1, onEscapeKeyDown: c, onPointerDownOutside: a, onFocusOutside: s, onInteractOutside: r, onDismiss: l, ...$ } = e, u = qe(Pt), [i, P] = D(null), d = (t = i == null ? void 0 : i.ownerDocument) !== null && t !== void 0 ? t : globalThis == null ? void 0 : globalThis.document, [, y] = D({}), f = N(
    o,
    (h) => P(h)
  ), g = Array.from(u.layers), [v] = [
    ...u.layersWithOutsidePointerEventsDisabled
  ].slice(-1), w = g.indexOf(v), b = i ? g.indexOf(i) : -1, m = u.layersWithOutsidePointerEventsDisabled.size > 0, F = b >= w, Z = Et((h) => {
    const _ = h.target, K = [
      ...u.branches
    ].some(
      (k) => k.contains(_)
    );
    !F || K || (a == null || a(h), r == null || r(h), h.defaultPrevented || l == null || l());
  }, d), M = xt((h) => {
    const _ = h.target;
    [
      ...u.branches
    ].some(
      (k) => k.contains(_)
    ) || (s == null || s(h), r == null || r(h), h.defaultPrevented || l == null || l());
  }, d);
  return tt((h) => {
    b === u.layers.size - 1 && (c == null || c(h), !h.defaultPrevented && l && (h.preventDefault(), l()));
  }, d), C(() => {
    if (i)
      return n && (u.layersWithOutsidePointerEventsDisabled.size === 0 && (se = d.body.style.pointerEvents, d.body.style.pointerEvents = "none"), u.layersWithOutsidePointerEventsDisabled.add(i)), u.layers.add(i), ie(), () => {
        n && u.layersWithOutsidePointerEventsDisabled.size === 1 && (d.body.style.pointerEvents = se);
      };
  }, [
    i,
    d,
    n,
    u
  ]), C(() => () => {
    i && (u.layers.delete(i), u.layersWithOutsidePointerEventsDisabled.delete(i), ie());
  }, [
    i,
    u
  ]), C(() => {
    const h = () => y({});
    return document.addEventListener(Q, h), () => document.removeEventListener(Q, h);
  }, []), /* @__PURE__ */ p(L.div, E({}, $, {
    ref: f,
    style: {
      pointerEvents: m ? F ? "auto" : "none" : void 0,
      ...e.style
    },
    onFocusCapture: T(e.onFocusCapture, M.onFocusCapture),
    onBlurCapture: T(e.onBlurCapture, M.onBlurCapture),
    onPointerDownCapture: T(e.onPointerDownCapture, Z.onPointerDownCapture)
  }));
});
function Et(e, o = globalThis == null ? void 0 : globalThis.document) {
  const t = H(e), n = x(!1), c = x(() => {
  });
  return C(() => {
    const a = (r) => {
      if (r.target && !n.current) {
        let $ = function() {
          ve(ht, t, l, {
            discrete: !0
          });
        };
        const l = {
          originalEvent: r
        };
        r.pointerType === "touch" ? (o.removeEventListener("click", c.current), c.current = $, o.addEventListener("click", c.current, {
          once: !0
        })) : $();
      } else
        o.removeEventListener("click", c.current);
      n.current = !1;
    }, s = window.setTimeout(() => {
      o.addEventListener("pointerdown", a);
    }, 0);
    return () => {
      window.clearTimeout(s), o.removeEventListener("pointerdown", a), o.removeEventListener("click", c.current);
    };
  }, [
    o,
    t
  ]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => n.current = !0
  };
}
function xt(e, o = globalThis == null ? void 0 : globalThis.document) {
  const t = H(e), n = x(!1);
  return C(() => {
    const c = (a) => {
      a.target && !n.current && ve(gt, t, {
        originalEvent: a
      }, {
        discrete: !1
      });
    };
    return o.addEventListener("focusin", c), () => o.removeEventListener("focusin", c);
  }, [
    o,
    t
  ]), {
    onFocusCapture: () => n.current = !0,
    onBlurCapture: () => n.current = !1
  };
}
function ie() {
  const e = new CustomEvent(Q);
  document.dispatchEvent(e);
}
function ve(e, o, t, { discrete: n }) {
  const c = t.originalEvent.target, a = new CustomEvent(e, {
    bubbles: !1,
    cancelable: !0,
    detail: t
  });
  o && c.addEventListener(e, o, {
    once: !0
  }), n ? et(c, a) : c.dispatchEvent(a);
}
const q = "focusScope.autoFocusOnMount", J = "focusScope.autoFocusOnUnmount", de = {
  bubbles: !1,
  cancelable: !0
}, Ot = /* @__PURE__ */ O((e, o) => {
  const { loop: t = !1, trapped: n = !1, onMountAutoFocus: c, onUnmountAutoFocus: a, ...s } = e, [r, l] = D(null), $ = H(c), u = H(a), i = x(null), P = N(
    o,
    (f) => l(f)
  ), d = x({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  C(() => {
    if (n) {
      let f = function(b) {
        if (d.paused || !r) return;
        const m = b.target;
        r.contains(m) ? i.current = m : A(i.current, {
          select: !0
        });
      }, g = function(b) {
        if (d.paused || !r) return;
        const m = b.relatedTarget;
        m !== null && (r.contains(m) || A(i.current, {
          select: !0
        }));
      }, v = function(b) {
        if (document.activeElement === document.body)
          for (const F of b) F.removedNodes.length > 0 && A(r);
      };
      document.addEventListener("focusin", f), document.addEventListener("focusout", g);
      const w = new MutationObserver(v);
      return r && w.observe(r, {
        childList: !0,
        subtree: !0
      }), () => {
        document.removeEventListener("focusin", f), document.removeEventListener("focusout", g), w.disconnect();
      };
    }
  }, [
    n,
    r,
    d.paused
  ]), C(() => {
    if (r) {
      ue.add(d);
      const f = document.activeElement;
      if (!r.contains(f)) {
        const v = new CustomEvent(q, de);
        r.addEventListener(q, $), r.dispatchEvent(v), v.defaultPrevented || (yt(Ft(be(r)), {
          select: !0
        }), document.activeElement === f && A(r));
      }
      return () => {
        r.removeEventListener(q, $), setTimeout(() => {
          const v = new CustomEvent(J, de);
          r.addEventListener(J, u), r.dispatchEvent(v), v.defaultPrevented || A(f ?? document.body, {
            select: !0
          }), r.removeEventListener(J, u), ue.remove(d);
        }, 0);
      };
    }
  }, [
    r,
    $,
    u,
    d
  ]);
  const y = Y((f) => {
    if (!t && !n || d.paused) return;
    const g = f.key === "Tab" && !f.altKey && !f.ctrlKey && !f.metaKey, v = document.activeElement;
    if (g && v) {
      const w = f.currentTarget, [b, m] = Ct(w);
      b && m ? !f.shiftKey && v === m ? (f.preventDefault(), t && A(b, {
        select: !0
      })) : f.shiftKey && v === b && (f.preventDefault(), t && A(m, {
        select: !0
      })) : v === w && f.preventDefault();
    }
  }, [
    t,
    n,
    d.paused
  ]);
  return /* @__PURE__ */ p(L.div, E({
    tabIndex: -1
  }, s, {
    ref: P,
    onKeyDown: y
  }));
});
function yt(e, { select: o = !1 } = {}) {
  const t = document.activeElement;
  for (const n of e)
    if (A(n, {
      select: o
    }), document.activeElement !== t) return;
}
function Ct(e) {
  const o = be(e), t = le(o, e), n = le(o.reverse(), e);
  return [
    t,
    n
  ];
}
function be(e) {
  const o = [], t = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (n) => {
      const c = n.tagName === "INPUT" && n.type === "hidden";
      return n.disabled || n.hidden || c ? NodeFilter.FILTER_SKIP : n.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; t.nextNode(); ) o.push(t.currentNode);
  return o;
}
function le(e, o) {
  for (const t of e)
    if (!_t(t, {
      upTo: o
    })) return t;
}
function _t(e, { upTo: o }) {
  if (getComputedStyle(e).visibility === "hidden") return !0;
  for (; e; ) {
    if (o !== void 0 && e === o) return !1;
    if (getComputedStyle(e).display === "none") return !0;
    e = e.parentElement;
  }
  return !1;
}
function At(e) {
  return e instanceof HTMLInputElement && "select" in e;
}
function A(e, { select: o = !1 } = {}) {
  if (e && e.focus) {
    const t = document.activeElement;
    e.focus({
      preventScroll: !0
    }), e !== t && At(e) && o && e.select();
  }
}
const ue = Dt();
function Dt() {
  let e = [];
  return {
    add(o) {
      const t = e[0];
      o !== t && (t == null || t.pause()), e = fe(e, o), e.unshift(o);
    },
    remove(o) {
      var t;
      e = fe(e, o), (t = e[0]) === null || t === void 0 || t.resume();
    }
  };
}
function fe(e, o) {
  const t = [
    ...e
  ], n = t.indexOf(o);
  return n !== -1 && t.splice(n, 1), t;
}
function Ft(e) {
  return e.filter(
    (o) => o.tagName !== "A"
  );
}
const me = "Popper", [he, ge] = pe(me), [Tt, Pe] = he(me), Rt = (e) => {
  const { __scopePopper: o, children: t } = e, [n, c] = D(null);
  return /* @__PURE__ */ p(Tt, {
    scope: o,
    anchor: n,
    onAnchorChange: c
  }, t);
}, St = "PopperAnchor", It = /* @__PURE__ */ O((e, o) => {
  const { __scopePopper: t, virtualRef: n, ...c } = e, a = Pe(St, t), s = x(null), r = N(o, s);
  return C(() => {
    a.onAnchorChange((n == null ? void 0 : n.current) || s.current);
  }), n ? null : /* @__PURE__ */ p(L.div, E({}, c, {
    ref: r
  }));
}), we = "PopperContent", [Nt, xo] = he(we), Lt = /* @__PURE__ */ O((e, o) => {
  var t, n, c, a, s, r, l, $;
  const { __scopePopper: u, side: i = "bottom", sideOffset: P = 0, align: d = "center", alignOffset: y = 0, arrowPadding: f = 0, avoidCollisions: g = !0, collisionBoundary: v = [], collisionPadding: w = 0, sticky: b = "partial", hideWhenDetached: m = !1, updatePositionStrategy: F = "optimized", onPlaced: Z, ...M } = e, h = Pe(we, u), [_, K] = D(null), k = N(
    o,
    (W) => K(W)
  ), [G, Re] = D(null), S = $t(G), Se = (t = S == null ? void 0 : S.width) !== null && t !== void 0 ? t : 0, ee = (n = S == null ? void 0 : S.height) !== null && n !== void 0 ? n : 0, Ie = i + (d !== "center" ? "-" + d : ""), Ne = typeof w == "number" ? w : {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...w
  }, te = Array.isArray(v) ? v : [
    v
  ], Le = te.length > 0, z = {
    padding: Ne,
    boundary: te.filter(Mt),
    // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
    altBoundary: Le
  }, { refs: Me, floatingStyles: oe, placement: ke, isPositioned: B, middlewareData: I } = ct({
    // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
    strategy: "fixed",
    placement: Ie,
    whileElementsMounted: (...W) => at(...W, {
      animationFrame: F === "always"
    }),
    elements: {
      reference: h.anchor
    },
    middleware: [
      st({
        mainAxis: P + ee,
        alignmentAxis: y
      }),
      g && it({
        mainAxis: !0,
        crossAxis: !1,
        limiter: b === "partial" ? pt() : void 0,
        ...z
      }),
      g && dt({
        ...z
      }),
      lt({
        ...z,
        apply: ({ elements: W, rects: re, availableWidth: Xe, availableHeight: je }) => {
          const { width: Ye, height: Ve } = re.reference, j = W.floating.style;
          j.setProperty("--radix-popper-available-width", `${Xe}px`), j.setProperty("--radix-popper-available-height", `${je}px`), j.setProperty("--radix-popper-anchor-width", `${Ye}px`), j.setProperty("--radix-popper-anchor-height", `${Ve}px`);
        }
      }),
      G && ut({
        element: G,
        padding: f
      }),
      kt({
        arrowWidth: Se,
        arrowHeight: ee
      }),
      m && ft({
        strategy: "referenceHidden",
        ...z
      })
    ]
  }), [ne, We] = Ee(ke), X = H(Z);
  ae(() => {
    B && (X == null || X());
  }, [
    B,
    X
  ]);
  const He = (c = I.arrow) === null || c === void 0 ? void 0 : c.x, Ue = (a = I.arrow) === null || a === void 0 ? void 0 : a.y, Ke = ((s = I.arrow) === null || s === void 0 ? void 0 : s.centerOffset) !== 0, [ze, Be] = D();
  return ae(() => {
    _ && Be(window.getComputedStyle(_).zIndex);
  }, [
    _
  ]), /* @__PURE__ */ p("div", {
    ref: Me.setFloating,
    "data-radix-popper-content-wrapper": "",
    style: {
      ...oe,
      transform: B ? oe.transform : "translate(0, -200%)",
      // keep off the page when measuring
      minWidth: "max-content",
      zIndex: ze,
      "--radix-popper-transform-origin": [
        (r = I.transformOrigin) === null || r === void 0 ? void 0 : r.x,
        (l = I.transformOrigin) === null || l === void 0 ? void 0 : l.y
      ].join(" ")
    },
    dir: e.dir
  }, /* @__PURE__ */ p(Nt, {
    scope: u,
    placedSide: ne,
    onArrowChange: Re,
    arrowX: He,
    arrowY: Ue,
    shouldHideArrow: Ke
  }, /* @__PURE__ */ p(L.div, E({
    "data-side": ne,
    "data-align": We
  }, M, {
    ref: k,
    style: {
      ...M.style,
      // if the PopperContent hasn't been placed yet (not all measurements done)
      // we prevent animations so that users's animation don't kick in too early referring wrong sides
      animation: B ? void 0 : "none",
      // hide the content if using the hide middleware and should be hidden
      opacity: ($ = I.hide) !== null && $ !== void 0 && $.referenceHidden ? 0 : void 0
    }
  }))));
});
function Mt(e) {
  return e !== null;
}
const kt = (e) => ({
  name: "transformOrigin",
  options: e,
  fn(o) {
    var t, n, c, a, s;
    const { placement: r, rects: l, middlewareData: $ } = o, i = ((t = $.arrow) === null || t === void 0 ? void 0 : t.centerOffset) !== 0, P = i ? 0 : e.arrowWidth, d = i ? 0 : e.arrowHeight, [y, f] = Ee(r), g = {
      start: "0%",
      center: "50%",
      end: "100%"
    }[f], v = ((n = (c = $.arrow) === null || c === void 0 ? void 0 : c.x) !== null && n !== void 0 ? n : 0) + P / 2, w = ((a = (s = $.arrow) === null || s === void 0 ? void 0 : s.y) !== null && a !== void 0 ? a : 0) + d / 2;
    let b = "", m = "";
    return y === "bottom" ? (b = i ? g : `${v}px`, m = `${-d}px`) : y === "top" ? (b = i ? g : `${v}px`, m = `${l.floating.height + d}px`) : y === "right" ? (b = `${-d}px`, m = i ? g : `${w}px`) : y === "left" && (b = `${l.floating.width + d}px`, m = i ? g : `${w}px`), {
      data: {
        x: b,
        y: m
      }
    };
  }
});
function Ee(e) {
  const [o, t = "center"] = e.split("-");
  return [
    o,
    t
  ];
}
const Wt = Rt, xe = It, Ht = Lt, Ut = /* @__PURE__ */ O((e, o) => {
  var t;
  const { container: n = globalThis == null || (t = globalThis.document) === null || t === void 0 ? void 0 : t.body, ...c } = e;
  return n ? /* @__PURE__ */ vt.createPortal(/* @__PURE__ */ p(L.div, E({}, c, {
    ref: o
  })), n) : null;
}), Oe = "Popover", [ye, Oo] = pe(Oe, [
  ge
]), V = ge(), [Kt, R] = ye(Oe), zt = (e) => {
  const { __scopePopover: o, children: t, open: n, defaultOpen: c, onOpenChange: a, modal: s = !1 } = e, r = V(o), l = x(null), [$, u] = D(!1), [i = !1, P] = Qe({
    prop: n,
    defaultProp: c,
    onChange: a
  });
  return /* @__PURE__ */ p(Wt, r, /* @__PURE__ */ p(Kt, {
    scope: o,
    contentId: rt(),
    triggerRef: l,
    open: i,
    onOpenChange: P,
    onOpenToggle: Y(
      () => P(
        (d) => !d
      ),
      [
        P
      ]
    ),
    hasCustomAnchor: $,
    onCustomAnchorAdd: Y(
      () => u(!0),
      []
    ),
    onCustomAnchorRemove: Y(
      () => u(!1),
      []
    ),
    modal: s
  }, t));
}, Bt = "PopoverAnchor", Xt = /* @__PURE__ */ O((e, o) => {
  const { __scopePopover: t, ...n } = e, c = R(Bt, t), a = V(t), { onCustomAnchorAdd: s, onCustomAnchorRemove: r } = c;
  return C(() => (s(), () => r()), [
    s,
    r
  ]), /* @__PURE__ */ p(xe, E({}, a, n, {
    ref: o
  }));
}), jt = "PopoverTrigger", Yt = /* @__PURE__ */ O((e, o) => {
  const { __scopePopover: t, ...n } = e, c = R(jt, t), a = V(t), s = N(o, c.triggerRef), r = /* @__PURE__ */ p(L.button, E({
    type: "button",
    "aria-haspopup": "dialog",
    "aria-expanded": c.open,
    "aria-controls": c.contentId,
    "data-state": Ae(c.open)
  }, n, {
    ref: s,
    onClick: T(e.onClick, c.onOpenToggle)
  }));
  return c.hasCustomAnchor ? r : /* @__PURE__ */ p(xe, E({
    asChild: !0
  }, a), r);
}), Ce = "PopoverPortal", [Vt, Zt] = ye(Ce, {
  forceMount: void 0
}), Gt = (e) => {
  const { __scopePopover: o, forceMount: t, children: n, container: c } = e, a = R(Ce, o);
  return /* @__PURE__ */ p(Vt, {
    scope: o,
    forceMount: t
  }, /* @__PURE__ */ p($e, {
    present: t || a.open
  }, /* @__PURE__ */ p(Ut, {
    asChild: !0,
    container: c
  }, n)));
}, U = "PopoverContent", qt = /* @__PURE__ */ O((e, o) => {
  const t = Zt(U, e.__scopePopover), { forceMount: n = t.forceMount, ...c } = e, a = R(U, e.__scopePopover);
  return /* @__PURE__ */ p($e, {
    present: n || a.open
  }, a.modal ? /* @__PURE__ */ p(Jt, E({}, c, {
    ref: o
  })) : /* @__PURE__ */ p(Qt, E({}, c, {
    ref: o
  })));
}), Jt = /* @__PURE__ */ O((e, o) => {
  const t = R(U, e.__scopePopover), n = x(null), c = N(o, n), a = x(!1);
  return C(() => {
    const s = n.current;
    if (s) return bt(s);
  }, []), /* @__PURE__ */ p(ot, {
    as: Ze,
    allowPinchZoom: !0
  }, /* @__PURE__ */ p(_e, E({}, e, {
    ref: c,
    trapFocus: t.open,
    disableOutsidePointerEvents: !0,
    onCloseAutoFocus: T(e.onCloseAutoFocus, (s) => {
      var r;
      s.preventDefault(), a.current || (r = t.triggerRef.current) === null || r === void 0 || r.focus();
    }),
    onPointerDownOutside: T(e.onPointerDownOutside, (s) => {
      const r = s.detail.originalEvent, l = r.button === 0 && r.ctrlKey === !0, $ = r.button === 2 || l;
      a.current = $;
    }, {
      checkForDefaultPrevented: !1
    }),
    onFocusOutside: T(
      e.onFocusOutside,
      (s) => s.preventDefault(),
      {
        checkForDefaultPrevented: !1
      }
    )
  })));
}), Qt = /* @__PURE__ */ O((e, o) => {
  const t = R(U, e.__scopePopover), n = x(!1), c = x(!1);
  return /* @__PURE__ */ p(_e, E({}, e, {
    ref: o,
    trapFocus: !1,
    disableOutsidePointerEvents: !1,
    onCloseAutoFocus: (a) => {
      var s;
      if ((s = e.onCloseAutoFocus) === null || s === void 0 || s.call(e, a), !a.defaultPrevented) {
        var r;
        n.current || (r = t.triggerRef.current) === null || r === void 0 || r.focus(), a.preventDefault();
      }
      n.current = !1, c.current = !1;
    },
    onInteractOutside: (a) => {
      var s, r;
      (s = e.onInteractOutside) === null || s === void 0 || s.call(e, a), a.defaultPrevented || (n.current = !0, a.detail.originalEvent.type === "pointerdown" && (c.current = !0));
      const l = a.target;
      ((r = t.triggerRef.current) === null || r === void 0 ? void 0 : r.contains(l)) && a.preventDefault(), a.detail.originalEvent.type === "focusin" && c.current && a.preventDefault();
    }
  }));
}), _e = /* @__PURE__ */ O((e, o) => {
  const { __scopePopover: t, trapFocus: n, onOpenAutoFocus: c, onCloseAutoFocus: a, disableOutsidePointerEvents: s, onEscapeKeyDown: r, onPointerDownOutside: l, onFocusOutside: $, onInteractOutside: u, ...i } = e, P = R(U, t), d = V(t);
  return nt(), /* @__PURE__ */ p(Ot, {
    asChild: !0,
    loop: !0,
    trapped: n,
    onMountAutoFocus: c,
    onUnmountAutoFocus: a
  }, /* @__PURE__ */ p(wt, {
    asChild: !0,
    disableOutsidePointerEvents: s,
    onInteractOutside: u,
    onEscapeKeyDown: r,
    onPointerDownOutside: l,
    onFocusOutside: $,
    onDismiss: () => P.onOpenChange(!1)
  }, /* @__PURE__ */ p(Ht, E({
    "data-state": Ae(P.open),
    role: "dialog",
    id: P.contentId
  }, d, i, {
    ref: o,
    style: {
      ...i.style,
      "--radix-popover-content-transform-origin": "var(--radix-popper-transform-origin)",
      "--radix-popover-content-available-width": "var(--radix-popper-available-width)",
      "--radix-popover-content-available-height": "var(--radix-popper-available-height)",
      "--radix-popover-trigger-width": "var(--radix-popper-anchor-width)",
      "--radix-popover-trigger-height": "var(--radix-popper-anchor-height)"
    }
  }))));
});
function Ae(e) {
  return e ? "open" : "closed";
}
const eo = zt, to = Xt, oo = Yt, De = Gt, Fe = qt, no = eo, ro = oo, co = to, Te = Ge.forwardRef(({ className: e, withPortal: o, align: t = "center", sideOffset: n = 4, ...c }, a) => {
  const s = (
    // <PopoverPrimitive.Portal>
    /* @__PURE__ */ ce.jsx(
      Fe,
      {
        ref: a,
        align: t,
        sideOffset: n,
        className: mt(
          "z-tooltip w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          e
        ),
        ...c
      }
    )
  );
  return o ? /* @__PURE__ */ ce.jsx(De, { children: s }) : s;
});
Te.displayName = Fe.displayName;
const yo = Object.assign(no, {
  Trigger: ro,
  Anchor: co,
  Content: Te,
  Portal: De
});
export {
  yo as P
};
